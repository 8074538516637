import { EventEmitter, Injectable, Output } from '@angular/core';
import { ICrmData, callEntry, callResult, teamsCallType } from '../models/caller.model';
import { CallClient, CallEndReason, TeamsCallAgent} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { AuthService } from './auth.service';
import { WebViewInteropService } from './web-view-interop.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class AcsService {
  //Event emitter
  // incomingCallEvent is emitted, when we receive an incoming call
  // finishedCallEvent is emitted, when the call is dropped from acs. For example: Accecpted in Teams
  // updateCallEvent is an subject which updates the current incoming call. For example: when the crm data is resolved
  @Output() incomingCallEvent$ = new EventEmitter<callEntry>();
  @Output() finishedCallEvent$ = new EventEmitter<callEntry>();
  @Output() updateCallEvent$ = new EventEmitter<callEntry>();

  private teamsAgent: TeamsCallAgent | undefined;
  private tokenCredential: AzureCommunicationTokenCredential | undefined;
  private callClient: CallClient = new CallClient();
  private callEntries: callEntry[] = [];
  private newCallEntry: callEntry = {
    id: "",
    type: teamsCallType.phoneNumber,
    timestamp: 0,
    displayName: "",
    number: "",
    result: callResult.Unknown,
    acceptedBy: "",
    transferredBy: "",
    forwardedBy: "",
    crmData: {
      custom: [],
      actions: []
    },
  };

  constructor(private authService: AuthService, private interop: WebViewInteropService, private logger: LoggerService) {}

  addCustomData(dataPromise?: Promise<ICrmData>) {
    this.newCallEntry.customPromise = dataPromise;
    this.updateCallEvent$.emit(this.newCallEntry);
  }

  callAgentConnectionChanged(args: any)
  {
    console.log("ConnectionStateChanged: ", args);
  }

  //#region CallAgent
  async initCallAgentRefresher(): Promise<boolean> {
    try{
      this.logger.log("Initializing Call Agent...");

      this.tokenCredential = new AzureCommunicationTokenCredential({
        tokenRefresher: () => this.authService.getAcsTokenRefresher(), //callback to refresh the acs token. Return value is Promise<string>
        refreshProactively: true, //automatically refreshing the token
      });

      try {
        this.teamsAgent = await this.callClient.createTeamsCallAgent(this.tokenCredential);
        this.authService.sendTelemetryData();
        this.teamsAgent.on('incomingCall', this.onIncomingCall.bind(this)); //we have to bind this in order to use this in the callback funtion

        this.teamsAgent.on('connectionStateChanged', this.callAgentConnectionChanged);
      }
      catch
      {
        this.logger.warn("Failed to initialize call agent");
        this.destroyCallAgent();
        throw new Error("Failed to initialize call agent");
      }
    }
    catch(err)
    {
      this.logger.error(err);
    }
    return true;
  }

  async destroyCallAgent() {
    this.logger.log("Destroying Agent...");
    this.logger.log(this.teamsAgent);
    this.tokenCredential?.dispose();
    this.teamsAgent?.off('incomingCall', this.onIncomingCall.bind(this));
    await this.teamsAgent?.dispose();
    this.logger.log(this.teamsAgent);
  }
  //#endregion

   //#region Call handling
  async onIncomingCall(call: any) {
    try {
      this.logger.log("ACS incoming call: ");
      this.logger.log(call.incomingCall);
        
      this.interop.postMessageToClient("incomingCallEvent"); //send message to the webview

      //delete all data from variable
      this.newCallEntry = {
        id: call.incomingCall._teamsCall._id + "-" + call.incomingCall._teamsCall.tsCall.callTelemetry.startTime, //we added the timestamp here to avoid duplicated ids
        type: call.incomingCall._teamsCall._callerIdentity.kind,
        timestamp: call.incomingCall._teamsCall.tsCall.callTelemetry.startTime,
        displayName: "",
        number: "",
        result: callResult.Ongoing,
        acceptedBy: "",
        transferredBy: "",
        forwardedBy: "",
        crmData: {
          custom: [],
          actions: []
        },
        customPromise: undefined
      };

      switch (call.incomingCall._teamsCall._callerIdentity.kind) {
        case teamsCallType.phoneNumber:
          this.logger.log("phoneNumber startTime: ", call.incomingCall._teamsCall.tsCall.callTelemetry.startTime);
          
          this.newCallEntry.displayName = call.incomingCall.callerInfo.displayName;
          this.newCallEntry.number = call.incomingCall.callerInfo.identifier?.phoneNumber;

          switch (call.incomingCall._teamsCall.tsCall.incomingCallType) {
            case "forward": //forwarded calls
              this.newCallEntry.forwardedBy = call.incomingCall._teamsCall.tsCall.transferorMri;
              break;
            case "transfer": //transferred calls
              this.newCallEntry.transferredBy = call.incomingCall._teamsCall.tsCall.transferorDisplayName;
              break;
            default: //default
              this.logger.log("phoneNumber type: ", call.incomingCall._teamsCall.tsCall.incomingCallType);
              this.newCallEntry.type = teamsCallType.phoneNumber;
              break;
          }
          break;
        case teamsCallType.microsoftTeamsUser:
          this.logger.log("microsoftTeamsUser", call.incomingCall._teamsCall.tsCall.callTelemetry.startTime);
          this.logger.log(call.incomingCall._callCommon._info._tsCall.participants);
          const number = call.incomingCall._callCommon._info._tsCall.participants.find((user: any) => user.id.includes("4:+"));
          if(number)
          {
            this.newCallEntry.number = number.id.replace("4:","");
            this.newCallEntry.forwardedBy = call.incomingCall.callerInfo.displayName;
          }
          switch(call.incomingCall._teamsCall.tsCall.incomingCallType)
          {
             default:
              this.logger.log("microsoftTeamsUser: ", call.incomingCall._teamsCall.tsCall.incomingCallType);
              break;
          }
          this.newCallEntry.type = teamsCallType.microsoftTeamsUser;
          this.newCallEntry.displayName = call.incomingCall._teamsCall._callerDisplayName;
          break;
        case teamsCallType.microsoftTeamsApp:
          this.logger.log("microsoftTeamsApp", call.incomingCall._teamsCall.tsCall.callTelemetry.startTime);
          this.logger.log(call.incomingCall._callCommon._info._tsCall.participants[0].id);
          this.logger.log(call.incomingCall._callCommon._info._tsCall.participants.find((user: any) => user.id.includes("4:+")));
          this.newCallEntry.forwardedBy = call.incomingCall._teamsCall.tsCall.callerMri;
          const numberApp = call.incomingCall._callCommon._info._tsCall.participants.find((user: any) => user.id.includes("4:+"));
          if(numberApp)
          {
            this.newCallEntry.number = numberApp.id.replace("4:","");
          }
          else
          {
            if (call.incomingCall.info._tsCall.callQueueInfo != undefined) {
              this.newCallEntry.displayName = call.incomingCall.info._tsCall.callQueueInfo.onBehalfOf.displayName;
            }
          }
          break;
        case teamsCallType.microsoftBot:
          this.logger.log("microsoftBot", call.incomingCall._teamsCall.tsCall.callTelemetry.startTime);
          //check if this is a call queue call or third party bot
          if (call.incomingCall.info._tsCall.callQueueInfo != undefined) {
            this.newCallEntry.displayName = call.incomingCall.info._tsCall.callQueueInfo.onBehalfOf.displayName;
            const numberBot = call.incomingCall.info._tsCall.callQueueInfo.onBehalfOf.id;
            if(numberBot)
            {
              this.newCallEntry.number = numberBot.id.replace("4:","")
            }
            this.newCallEntry.forwardedBy = call.incomingCall.info._tsCall.callQueueInfo.details.id;
          }
          else
          {
            this.newCallEntry.forwardedBy = call.incomingCall._teamsCall.tsCall.callerMri;
          }
          break;
        default:
          this.logger.log("Default");
          break;
      }

      this.callEntries.push(this.newCallEntry);
      this.logger.log("Current calls: ", this.callEntries);

      this.incomingCallEvent$.emit(this.newCallEntry)
      this.updateCallEvent$.emit(this.newCallEntry); //in this case we have to update the current call for the caller information, because we don't fetch any crm data here
      /*currently it is not possible to reference to a separate function. we get the error message, that the callbackfunction is undefined
      Workaround: use arrow function
      */
      call.incomingCall.on('callEnded', (endReason: {callEndReason: CallEndReason}) => {
        //this.logger.log(call.incomingCall.callEndReason);
        this.logger.log(endReason);
        
        var currentEntry = this.callEntries.find(i => i.id === call.incomingCall._teamsCall._id + "-" + call.incomingCall._teamsCall.tsCall.callTelemetry.startTime); //find the call entry from array by id. we added the timestamp here to avoid duplicated ids

        this.interop.postMessageToClient("endCallEvent"); //send message to the webview
        //check reasoncode
        if (currentEntry) {
          this.updateCall(currentEntry, call, endReason.callEndReason.code);
          if (endReason.callEndReason.subCode) {
            this.updateCall(currentEntry, call, endReason.callEndReason.subCode);
          }
        }

        this.finishedCallEvent$.emit(currentEntry);
        this.callEntries.splice(this.callEntries.indexOf(currentEntry!),1); //we have to delete the emitted call entries
      }); //subscribe to the callEnded Event
    }
    catch (err) {
      if (err instanceof Error) {
        this.logger.log("ACS incoming call: ", err.message);
      }
    }
  }

  updateCall(callEntry: callEntry, call: any , code : number)
  {
    const phrase = call.incomingCall._teamsCall.tsCall.callEndDiagnosticsInfo.phrase;
    this.logger.log(code, phrase);
    switch (code) {
      case 487: //Verpasst/Aufgelegt Teams Call
        callEntry.result = callResult.Unanswered;
        break;
      case 603: //Declined
        callEntry.result = callResult.Declined;
        break;
      case 10003: //Angenommen
        this.logger.log("Call accepted by: ", call.incomingCall._teamsCall.tsCall.acceptedElsewhereBy.displayName);
        callEntry.acceptedBy = call.incomingCall._teamsCall.tsCall.acceptedElsewhereBy.displayName;
        if(!callEntry.acceptedBy) //Check if null
        {
          callEntry.acceptedBy = "";
        }
        callEntry.result = callResult.Accepted;
        break;
      case 0: //Current workaround for ignored teams calls
      case 4097: // EndForAllInitiatedByLocalUser
      case 5010: //This converstation has ended as only one participant was remaining in the conversation
      case 5013: //This conversation has ended as no one else has joined the group call.
      case 5300: //participant removed
      case 7000: //Call Queue Verpasst
      case 540487: //Verpasst/Aufgelegt
      case 10004: //Verpasst
        callEntry.result = callResult.Unanswered;
        break;
      case 10024: //Abgelehnt, auch fuer Call Queue
        callEntry.result = callResult.Declined;
        break;
      default:
        this.logger.log("Default ", code, " : ", phrase);
        callEntry.result = callResult.Unknown;
    }
  }
}
